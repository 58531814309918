export enum ProfileEnum {
    Administrador,
    GerenteDeVendas,
    GerenteDeProjetos,
    GerenteDeSuporte,
    GerenteDeFormalizacao,
    GerenteDeEstoque,
    Operador,
    Tecnico,
    ConsultorDeVendas,
    Indicador,
    Cliente,
    ConsultorDeVendasInterno,
    GerenteFinanceiro,
}

export const ProfileEnumLabel = new Map<number, string>([
    [ProfileEnum.Administrador, 'Administrador'],
    [ProfileEnum.GerenteDeVendas, 'Gerente De Vendas'],
    [ProfileEnum.GerenteDeProjetos, 'Gerente De Projetos'],
    [ProfileEnum.GerenteDeSuporte, 'Gerente De Suporte'],
    [ProfileEnum.GerenteDeFormalizacao, 'Gerente De Formalização'],
    [ProfileEnum.GerenteDeEstoque, 'Gerente De Estoque'],
    [ProfileEnum.Operador, 'Operador'],
    [ProfileEnum.Tecnico, 'Técnico'],
    [ProfileEnum.ConsultorDeVendas, 'Consultor De Vendas'],
    [ProfileEnum.Indicador, 'Indicador'],
    [ProfileEnum.Cliente, 'Cliente'],
    [ProfileEnum.ConsultorDeVendasInterno, 'Consultor De Vendas Interno'],
    [ProfileEnum.GerenteFinanceiro, 'Gerente Financeiro'],
])
