import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ButtonModule } from "primeng/button";
import { ModalProposalPdfComponent } from "./modal-proposal-pdf.component";
import { ToastModule } from "primeng/toast";
import { InputTextModule } from "primeng/inputtext";
import { DropdownModule } from "primeng/dropdown";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ButtonModule,
        DropdownModule,
        InputTextModule,
        ToastModule,
    ],
    declarations: [ModalProposalPdfComponent],
})
export class ModalProposalPdfModule {}
