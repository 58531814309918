<div class="container mt-3 p-5">
    <div class="align-items-center mt-3">
        <p-calendar class="mr-2" [showIcon]="true" [showWeek]="true" [(ngModel)]="dateStart" [maxDate]="maxDate"
            [showTransitionOptions]="'300ms'" [hideTransitionOptions]="'300ms'" placeholder="Data Inicial"
            dateFormat="dd/mm/yy" (onSelect)="filterByDates($event, 0)">
        </p-calendar>
        <p-calendar [showIcon]="true" [showWeek]="true" [(ngModel)]="dateEnd" [maxDate]="maxDate"
            [showTransitionOptions]="'300ms'" [hideTransitionOptions]="'300ms'" placeholder="Data Final"
            dateFormat="dd/mm/yy" (onSelect)="filterByDates($event, 1)">
        </p-calendar>
    </div>
    <div class="align-items-center">
        <p-multiSelect class="textUppercase mr-2" [options]="sellersOptions" optionLabel="name"
            placeholder="Selecione Vendedor" (onChange)="setSeller($event)">
        </p-multiSelect>
        <input pInputText class="p-inputtext mb-3" style="background: white;" placeholder="Digite a cidade"
            [(ngModel)]="clientsFilterRequest.cidade">
    </div>
    <div class="align-items-center">
        <p-dropdown class="mr-2 textUppercase" [options]="personsOptions" optionLabel="name" [showClear]="true"
            (onChange)="setClientsByType($event)" optionValue="value" filterPlaceholder="Digite para filtrar">
        </p-dropdown>
        <p-dropdown [options]="statesOptions" optionLabel="name" [showClear]="true" class="textUppercase"
            [(ngModel)]="clientsFilterRequest.estado" optionValue="value" filterPlaceholder="Digite para filtrar">
        </p-dropdown>
    </div>
    <div class="align-items-center">
        <p-multiSelect class="mr-2 textUppercase" [options]="withdrawalOptions" optionLabel="name"
            placeholder="Selecione o Motivo" (onChange)="setWithdrawal($event)">
        </p-multiSelect>
    </div>
    <br>
    <p-button class="mr-2" label="Excel" icon="pi pi-download" size="small" (click)="getExcelList()" *ngIf="isAdmin()"></p-button>
    <p-button class="mr-2" label="Limpar" icon="pi pi-sync" size="small" (click)="clearSearch()"></p-button>
    <p-button label="Filtrar" icon="pi pi-check" size="small" (click)="getClients()"></p-button>

    <div class="flex col-12 mt-2">
        <div class="col-6">
            <div class="card flex justify-content-start">
                <div class="flex flex-wrap gap-3">
                    Projeto:
                    <div class="flex align-items-center gap-1">
                        <p-checkbox class="mr-2" [(ngModel)]="clientsFilterRequest.andamento" label="Em Andamento"
                            [binary]="true">
                        </p-checkbox>
                        <p-checkbox class="mr-2" [(ngModel)]="clientsFilterRequest.aconcluir" label="A Concluir"
                            [binary]="true">
                        </p-checkbox>
                        <p-checkbox class="mr-2" [(ngModel)]="clientsFilterRequest.enviado" label="Enviado"
                            [binary]="true">
                        </p-checkbox>
                        <p-checkbox class="mr-2" [(ngModel)]="clientsFilterRequest.concluido" label="Concluído"
                            [binary]="true">
                        </p-checkbox>
                    </div>
                </div>
            </div>
            <div class="card flex justify-content-start mt-2">
                <div class="flex flex-wrap gap-3">
                    Financiamento:
                    <div class="flex align-items-center gap-1">
                        <p-checkbox class="mr-2" [(ngModel)]="clientsFilterRequest.analise" label="Análise"
                            [binary]="true">
                        </p-checkbox>
                        <p-checkbox class="mr-2" [(ngModel)]="clientsFilterRequest.documentos" label="Documentos"
                            [binary]="true">
                        </p-checkbox>
                        <p-checkbox class="mr-2" [(ngModel)]="clientsFilterRequest.aprovado" label="Aprovado"
                            [binary]="true">
                        </p-checkbox>
                        <p-checkbox class="mr-2" [(ngModel)]="clientsFilterRequest.reprovado" label="Reprovado"
                            [binary]="true">
                        </p-checkbox>
                    </div>
                </div>
            </div>
            <div class="card flex justify-content-start mt-3">
                <div class="flex align-items-center gap-1">
                    <span class="mr-2">Desistência do Projeto: </span>
                    <p-selectButton [options]="desistenceOptions" [(ngModel)]="clientsFilterRequest.isDesistiu"
                        optionLabel="label" optionValue="value">
                    </p-selectButton>
                </div>
            </div>
        </div>
        <div class="col-6" *ngIf="isFilter">
            <div class="card flex align-items-center justify-content-end gap-2 flex-wrap">
                <p-chip [label]="'Total Projetos ' + (totalPrice | currency:'BRL')" icon="pi pi-wallet"></p-chip>
            </div>
        </div>
    </div>

    <div class="grid justify-content-center mt-3">
        <p-fieldset class="col-12 md:col-6 lg:col-4 p-3"
            [legend]="cliente.id + ' - ' + convertValues.convertNameCapitalize(cliente.nome | lowercase)"
            *ngFor="let cliente of returnedArray">
            <span>{{'Cadastrado em ' + cliente.dataCadastro}}</span>
            <div class="col-12">
                <li><strong>Tipo: </strong>{{cliente.tipo}}</li>
                <li><strong>Telefone: </strong>{{convertValues.maskPhone(cliente.telefone)}}</li>
                <li class="capitalize"><strong>Vendedor: </strong>{{cliente.vendedor | lowercase}}</li>
                <li class="capitalize"><strong>Cidade: </strong>{{cliente.cidade | lowercase}} - {{cliente.estado |
                    uppercase}}</li>
                <li><strong>Tamanho Projeto: </strong>{{cliente.tamanhoProjeto + ' KWH'}}</li>
                <li><strong>Valor Projeto: </strong>{{cliente.valorProjeto | currency:'BRL'}}</li>
                <li *ngIf="cliente.isDesistiu">
                    <strong>Motivo da Desistência: </strong>{{cliente.motivoDesistencia}}
                </li>
            </div>
            <div class="divEtapa">
                <h4>Projeto</h4>
                <h4>Financiamento</h4>
            </div>
            <p-badge [value]="cliente.etapa | uppercase" [severity]="getStepColor(cliente.etapa)"></p-badge>
            <p-badge class="ml-2" [value]="cliente.situacao | uppercase"
                [severity]="getSituationColor(cliente.situacao)">
            </p-badge>
            <br><br>
            <p-button label="PDF" icon="pi pi-download" size="small"
                (click)="downloadPdfClient(cliente.id, cliente.nome)" *ngIf="!isFinancialManager()">
            </p-button>
        </p-fieldset>
    </div>

    <div class="mt-3 p-datatable-striped">
        <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows"
            [totalRecords]="clientsFull?.length">
        </p-paginator>
    </div>

    <div class="d-flex align-items-center justify-content-center" *ngIf="clientsFull?.length == 0">
        <h5><strong>Nenhum registro encontrato.</strong></h5>
    </div>
</div>
