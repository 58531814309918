import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CrmBoardComponent } from "./pages/crm-board/crm-board.component";
import { CrmReportComponent } from "./pages/crm-report/crm-report.component";
import { CrmTimelineComponent } from "./components/crm-timeline/crm-timeline.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ClientCardComponent } from "./components/crm-client-card/crm-client-card.component";
import { DialogService } from "primeng/dynamicdialog";
import { CrmService } from "./services/crm-service.service";
import { AvatarModule } from "primeng/avatar";
import { ButtonModule } from "primeng/button";
import { InputTextModule } from "primeng/inputtext";
import { DialogModule } from "primeng/dialog";
import { CrmRegisterComponent } from "./pages/crm-register/crm-register.component";
import { TimelineModule } from "primeng/timeline";
import { CardModule } from "primeng/card";
import { InputMaskModule } from "primeng/inputmask";
import { PipesModule } from "src/app/shared/pipes/pipes.module";
import { CrmWhatsappComponent } from "./components/crm-whatsapp/crm-whatsapp.component";
import { InputTextareaModule } from "primeng/inputtextarea";
import { CrmTransferCardComponent } from "./components/crm-transfer-card/crm-transfer-card.component";
import { CrmRegisterClientComponent } from "./pages/crm-register-client/crm-register-client.component";
import { UserService } from "../services/user.service";
import { DropdownModule } from "primeng/dropdown";
import { CrmLoadingComponent } from "./components/crm-loading/crm-loading.component";
import { TooltipModule } from "primeng/tooltip";
import { BadgeModule } from "primeng/badge";
import { ListboxModule } from 'primeng/listbox';
import { AuthService } from "../services/auth.service";
import { MessageService } from "primeng/api";
import { SpinnerService } from "../services/spinner.service";
import { ToastModule } from "primeng/toast";
import { CalendarModule } from "primeng/calendar";
import { InputNumberModule } from "primeng/inputnumber";
import { CheckboxModule } from "primeng/checkbox";
import { BancoService } from "../services/banco.service";
import { ClientService } from "../services/client.service";
import { EnderecoService } from "../services/endereco.service";
import { GetAddressService } from "../services/getAddress.service";
import { ParcelaService } from "../services/parcela.service";

@NgModule({
    declarations: [
        CrmBoardComponent,
        CrmTimelineComponent,
        CrmRegisterComponent,
        CrmLoadingComponent,
        CrmRegisterClientComponent,
        CrmReportComponent,
        CrmWhatsappComponent,
        CrmTransferCardComponent,
        ClientCardComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AvatarModule,
        DialogModule,
        BadgeModule,
        ListboxModule,
        TimelineModule,
        ToastModule,
        InputMaskModule,
        CheckboxModule,
        InputNumberModule,
        InputTextareaModule,
        CardModule,
        PipesModule,
        InputMaskModule,
        TooltipModule,
        DropdownModule,
        CalendarModule,
        ButtonModule,
        InputTextModule,
    ],
    providers: [
        DialogService,
        CrmService,
        MessageService,
        SpinnerService,
        AuthService,
        UserService,
        GetAddressService,
        ClientService,
        BancoService,
        EnderecoService,
        ParcelaService
    ],
})
export class CrmModule {}
