import { Component, OnInit } from "@angular/core";
import {
    AbstractControl,
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";
import { MessageService, SelectItem } from "primeng/api";
import { ProfileEnum } from "src/app/providers/Enums/profile-Enum";
import { Cliente } from "src/app/providers/models/cliente";
import { ProjetoCliente } from "src/app/providers/models/projeto-cliente";
import { AuthService } from "src/app/services/auth.service";
import { ClientService } from "src/app/services/client.service";
import { ConvertValues } from "src/app/services/convert-values.service";
import { EnderecoService } from "src/app/services/endereco.service";
import { GetAddressService } from "src/app/services/getAddress.service";
import { ProjectClientService } from "src/app/services/project-client.service";
import { SpinnerService } from "src/app/services/spinner.service";
import { UsuarioService } from "src/app/services/usuario.service";
import { FormValidations } from "src/app/shared/form-validations";

@Component({
    selector: "app-project-client-register",
    templateUrl: "./project-client-register.component.html",
    styleUrls: ["./project-client-register.component.scss"],
})
export class ProjectClientRegisterComponent implements OnInit {
    textButton: string = "Cadastrar";
    textAction: string = " será Cadastrado. Deseja continuar?";
    labelPagina: string = "Cadastrar Projeto";
    typeProject: boolean = false;
    visible: boolean = false;
    clientName: string = "";
    projectClientForm!: FormGroup;
    projectClient = {} as ProjetoCliente;
    profile: number = 0;
    clients: Cliente[] = [];
    projectsOptions: SelectItem[] = [];
    clientsOptions: SelectItem[] = [];
    addressOptions: SelectItem[] = [];
    client = {} as Cliente;
    responsiblesOptions: SelectItem[] = [];
    index: boolean = true;
    indexAddress: boolean = true;
    amount: number = 0;
    projectClientId: number = 0;

    constructor(
        private readonly fb: FormBuilder,
        private readonly getAddressService: GetAddressService,
        private readonly messageService: MessageService,
        private readonly router: Router,
        public sanitizer: DomSanitizer,
        private readonly authService: AuthService,
        private readonly clientService: ClientService,
        private readonly addressService: EnderecoService,
        private readonly spinnerService: SpinnerService,
        private readonly userService: UsuarioService,
        private readonly projectClientService: ProjectClientService,
        private readonly activatedRouter: ActivatedRoute
    ) {
        this.createForm();
        this.projectClientId =
            +this.activatedRouter.snapshot.paramMap.get("id")!;
        this.profile = +this.authService.perfil();

        if (this.profile == ProfileEnum.Indicador) window.history.back();

        document.body.addEventListener("contextmenu", function (e) {
            e.preventDefault();
        });
    }

    async ngOnInit() {
        this.projectsOptions.push({ label: "Projeto Gd", value: 1 });

        await this.getClients();
        await this.getResponsibles();

        if (this.projectClientId != 0) {
            this.getProjectClientById(this.projectClientId);
            this.typeProject = true;
        } else {
            this.index = false;
        }
    }

    isAdminOrGeranteProjetos() {
        return (
            this.profile == ProfileEnum.Administrador ||
            this.profile == ProfileEnum.GerenteDeProjetos
        );
    }

    isAdmin() {
        return this.profile == ProfileEnum.Administrador;
    }

    isFinancialManager() {
        return this.profile == ProfileEnum.GerenteFinanceiro;
    }

    async getClients() {
        this.spinnerService.show();
        if (this.projectClientId == 0 && this.isAdminOrGeranteProjetos()) {
            await this.clientService
                .getAllClientsNames()
                .then((result) => {
                    this.clientsOptions.push({ label: "", value: null });
                    result.map((element) => {
                        this.clientsOptions.push({
                            label: element.id + " - " + element.nome,
                            value: element.nome,
                        });
                    });
                })
                .catch((error) => {
                    this.messageService.add({
                        severity: "error",
                        summary: "Erro!",
                        detail: `Erro ao tentar listar Clientes: ${error.message}.`,
                        life: 5000,
                    });
                })
                .finally(() => this.spinnerService.hide());
        }
    }

    async getResponsibles() {
        await this.userService
            .getGerentesSuporte()
            .then(
                (result) => {
                    result = result.filter((x) => x.isActive == true);

                    result.map((element) => {
                        this.responsiblesOptions.push({
                            label: element.nome,
                            value: element.nome,
                        });
                    });
                },
                (error: any) => {
                    this.messageService.add({
                        severity: "error",
                        summary: "Erro!",
                        detail: `Erro ao tentar listar Gerentes de Suporte.`,
                    });
                }
            )
            .finally();
    }

    async getProjectClientById(projetoClienteId: number) {
        this.spinnerService.show();
        if (this.isAdminOrGeranteProjetos()) this.textButton = "Editar";
        if (this.profile == ProfileEnum.GerenteDeSuporte)
            this.textButton = "Finalizar";

        await this.projectClientService
            .getProjectClientById(projetoClienteId)
            .then(
                (result) => {
                    this.projectClient = result;

                    this.setprojectClientForm(result);
                    this.localization();

                    if (result.status) {
                        if (this.isAdminOrGeranteProjetos()) {
                            this.labelPagina = "Editar Projeto";
                            this.index = false;
                        }
                        if (this.profile == ProfileEnum.GerenteDeSuporte) {
                            this.labelPagina = "Finalizar Projeto";
                            this.index = true;
                        }
                    } else {
                        if (
                            this.isAdminOrGeranteProjetos() ||
                            this.profile == ProfileEnum.GerenteDeSuporte
                        ) {
                            this.labelPagina = "Projeto Finalizado";
                            this.index = true;
                        }
                    }
                },
                (error: any) => {
                    this.messageService.add({
                        severity: "error",
                        summary: "Erro!",
                        detail: `Erro ao tentar buscar Projeto do Cliente: ${this.projectClient.clienteNome}.`,
                    });
                }
            )
            .finally(() => this.spinnerService.hide());
    }

    cssValidator(campoForm: FormControl | AbstractControl): any {
        return { "ng-invalid ng-dirty": campoForm.errors && campoForm.touched };
    }

    get getProjectClientForm() {
        return this.projectClientForm.controls;
    }

    createForm() {
        this.projectClientForm = this.fb.group({
            id: [null],
            clienteId: [null, Validators.required],
            clienteNome: [
                null,
                [
                    Validators.required,
                    Validators.minLength(3),
                    Validators.maxLength(60),
                ],
            ],
            concessionaria: [null, Validators.required],
            telefone: [null, Validators.required],
            telefone2: [null],
            cpf: [null, Validators.required],
            rg: [null, Validators.required],
            cep: [null, [Validators.required, FormValidations.cepValidator]],
            rua: [null, Validators.required],
            numero: [null, Validators.required],
            complemento: [null],
            cidade: ["", Validators.required],
            estado: [null, Validators.required],
            bairro: [null, Validators.required],
            cadastradoPor: [this.authService.userFullName()],
            responsavel: [null, Validators.required],
            observacao: ["", null],
            status: [false, Validators.required],
            tamanhoProjeto: [null, Validators.required],
            isActive: [true, Validators.required],
            tipoProjeto: [null, Validators.required],
        });
    }

    async setprojectClientForm(projetoCliente: ProjetoCliente) {
        this.projectClientForm.get("id")?.setValue(projetoCliente.id);
        this.projectClientForm
            .get("dataCadastro")
            ?.setValue(projetoCliente.dataCadastro);
        this.projectClientForm
            .get("isActive")
            ?.setValue(projetoCliente.isActive);
        this.projectClientForm
            .get("cadastradoPor")
            ?.setValue(projetoCliente.cadastradoPor);
        this.projectClientForm
            .get("clienteId")
            ?.setValue(projetoCliente.clienteId);
        this.projectClientForm
            .get("cliente")
            ?.setValue(projetoCliente.clienteNome);
        this.projectClientForm.get("status")?.setValue(projetoCliente.status);
        this.projectClientForm.get("rg")?.setValue(projetoCliente.rg);
        this.projectClientForm.get("cpf")?.setValue(projetoCliente.cpf);
    }

    async setClient(event: any) {
        await this.clientService
            .getClientByName(event.value)
            .then((result) => {
                this.getAddressByClientId(result.id, result.nome);
                this.projectClientForm.get("rg")?.setValue(result.rg);
                this.projectClientForm.get("cpf")?.setValue(result.cpf);
                this.projectClientForm
                    .get("concessionaria")
                    ?.setValue(result.concessionaria);
                this.projectClientForm.get("clienteId")?.setValue(result.id);
                this.projectClientForm.get("cep")?.setValue(result.cep);
                this.projectClientForm
                    .get("telefone")
                    ?.setValue(result.telefone);

                if (result.telefone2 == undefined || result.telefone2 == null)
                    this.projectClientForm.get("telefone2")?.setValue("");
                else
                    this.projectClientForm
                        .get("telefone2")
                        ?.setValue(result.telefone2);

                this.projectClientForm.get("rua")?.setValue(result.rua);
                this.projectClientForm.get("bairro")?.setValue(result.bairro);
                this.projectClientForm.get("cidade")?.setValue(result.cidade);
                this.projectClientForm
                    .get("tamanhoProjeto")
                    ?.setValue(result.tamanhoProjeto);
                this.projectClientForm.get("estado")?.setValue(result.estado);
                this.projectClientForm.get("numero")?.setValue(result.numero);
                this.projectClientForm
                    .get("complemento")
                    ?.setValue(result.complemento);
                this.localization();
            })
            .finally();
    }

    async getAddressByClientId(clienteId: number, clienteNome: string) {
        await this.addressService
            .getEnderecosPorIdCliente(clienteId)
            .then(
                (result) => {
                    this.addressOptions = [];
                    result.map((element) => {
                        this.addressOptions.push({
                            label: element.rua,
                            value: element,
                        });
                    });

                    this.indexAddress = result.length == 0 ? true : false;
                },
                (error: any) => {
                    this.messageService.add({
                        severity: "error",
                        summary: "Erro!",
                        detail: `Erro ao tentar buscar Endereços do Cliente: ${clienteNome}.`,
                    });
                }
            )
            .finally();
    }

    setAddressOs(event: any) {
        this.projectClientForm.get("rg")?.setValue(this.client.rg);
        this.projectClientForm.get("cpf")?.setValue(this.client.cpf);
        this.projectClientForm
            .get("concessionaria")
            ?.setValue(this.client.concessionaria);
        this.projectClientForm.get("clienteId")?.setValue(this.client.id);
        this.projectClientForm.get("telefone")?.setValue(this.client.telefone);

        if (this.client.telefone2 == undefined || this.client.telefone2 == null)
            this.projectClientForm.get("telefone2")?.setValue("");
        else
            this.projectClientForm
                .get("telefone2")
                ?.setValue(this.client.telefone2);

        this.projectClientForm.get("cep")?.setValue(event.value.cep);
        this.projectClientForm.get("rua")?.setValue(event.value.rua);
        this.projectClientForm.get("bairro")?.setValue(event.value.bairro);
        this.projectClientForm.get("cidade")?.setValue(event.value.cidade);
        this.projectClientForm
            .get("tamanhoProjeto")
            ?.setValue(event.value.tamanhoProjeto);
        this.projectClientForm.get("estado")?.setValue(event.value.estado);
        this.projectClientForm.get("numero")?.setValue(event.value.numero);
        this.projectClientForm
            .get("complemento")
            ?.setValue(event.value.complemento);
        this.localization();
    }

    setProjectType(event: any) {
        this.projectClientForm.get("tipoProjeto")?.setValue(event.value);
        this.typeProject = true;
    }

    async registertProjectClient(projetoClienteId: number) {
        this.spinnerService.show();
        this.projectClient = { ...this.projectClientForm.value };

        if (projetoClienteId == 0) {
            await this.projectClientService
                .postProjectClient(
                    this.projectClient,
                    this.authService.userId()
                )
                .then(
                    (retorno: ProjetoCliente) => {
                        this.messageService.add({
                            severity: "success",
                            summary: "Sucesso!",
                            detail: `Projeto do Cliente: ${retorno.clienteNome} cadastrado com sucesso.`,
                        });
                        setTimeout(() => {
                            this.router.navigateByUrl(
                                `/app/projeto-cliente/editar/${retorno.id}`
                            );
                        }, 1500);
                    },
                    (error: any) => {
                        this.messageService.add({
                            severity: "error",
                            summary: "Erro!",
                            detail: `Erro ao tentar cadastrar Projeto do Cliente: ${this.projectClient.clienteNome}.`,
                        });
                    }
                )
                .finally(() => this.spinnerService.hide());
        } else {
            await this.projectClientService
                .putProjectClient(this.projectClient, this.authService.userId())
                .then(
                    (retorno: ProjetoCliente) => {
                        if (this.profile == ProfileEnum.GerenteDeSuporte) {
                            this.messageService.add({
                                severity: "success",
                                summary: "Sucesso!",
                                detail: `Projeto do Cliente: ${retorno.clienteNome} finalizado.`,
                                life: 3500,
                            });
                        } else {
                            this.messageService.add({
                                severity: "success",
                                summary: "Sucesso!",
                                detail: `Projeto do Cliente: ${retorno.clienteNome} editado.`,
                                life: 3500,
                            });
                        }
                    },
                    (error: any) => {
                        const message =
                            this.profile == ProfileEnum.GerenteDeSuporte
                                ? `Erro ao tentar finalizar Projeto do Cliente: ${this.projectClient.clienteNome}`
                                : `Erro ao tentar editar Projeto do Cliente: ${this.projectClient.clienteNome}`;
                        this.messageService.add({
                            severity: "error",
                            summary: "Erro!",
                            detail: message,
                            life: 3500,
                        });
                    }
                )
                .finally(() => {
                    this.spinnerService.hide();
                    setTimeout(() => {
                        this.router.navigateByUrl(`/app/projeto-cliente/lista`);
                    }, 2500);
                });
        }
    }

    onSubmit(): void {
        if (this.projectClientForm.get("observacao")?.value == undefined)
            this.projectClientForm.get("observacao")?.setValue("");

        if (this.projectClientForm.invalid) {
            Object.keys(this.getProjectClientForm).forEach((key) => {
                this.projectClientForm.get(key).markAsTouched();
            });
            return this.messageService.add({
                severity: "warn",
                summary: "Atenção!",
                detail: `Formulário com campos inválidos.`,
            });
        } else {
            this.registertProjectClient(this.projectClientId);
        }
    }

    resetForm(): void {
        this.projectClientForm.reset();
    }

    getCep(event: any) {
        if (event.target.value == "58700-000") {
            this.projectClientForm.patchValue({
                cidade: "Patos",
                cep: "58700000",
                estado: "PB",
                rua: null,
                numero: null,
                bairro: null,
            });
        }
        if (event.target.value != "58700-000" && event.target.value != "") {
            this.getAddressService
                .getAddressByZipCode(event.target.value)
                .subscribe((result: any) => {
                    if (result.erro || event.target.value == "") {
                        this.messageService.add({
                            severity: "error",
                            summary: "Erro!",
                            detail: `CEP digitado é inválido.`,
                        });
                        return this.projectClientForm.patchValue({
                            rua: null,
                            cidade: null,
                            estado: null,
                            bairro: null,
                        });
                    }
                    result.cep = result.cep.split("-").join("");
                    this.projectClientForm.patchValue({
                        rua: result.logradouro,
                        cidade: result.localidade,
                        estado: result.uf,
                        bairro: result.bairro,
                    });
                });
        }
    }

    showDialog(projectClient: ProjetoCliente) {
        if (this.textButton == "Finalizar")
            this.textAction = "será finalizado. Deseja continuar?";
        if (this.textButton == "Editar")
            this.textAction = "será editada. Deseja continuar?";

        this.clientName = ConvertValues.convertNameCapitalize(
            projectClient.clienteNome
        );
        this.projectClient = projectClient;
        this.visible = true;
    }

    localization(): string {
        if (this.projectClient.numero != undefined) {
            if (this.projectClient.numero != "") {
                const rua = this.projectClient.rua;
                const numero = Number(this.projectClient.numero)
                    ? this.projectClient.numero
                    : "";
                const bairro = this.projectClient.bairro;
                const cidade = this.projectClient.cidade;
                const uf = this.projectClient.estado;
                const cep = this.projectClient.cep.replace(
                    /^([\d]{5})\-?([\d]{3})/,
                    "$1-$2"
                );
                const endereco = `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3577.983846352305!2d0!3d0!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94deae1bd431896b%3A0xedbf04c58d8d9400!2sR.%20${rua}%2C%20${numero}%20-%20${bairro}%2C%20${cidade}%20-%20${uf}%2C%20${cep}!5e0!3m2!1spt-PT!2sbr!4v1665807086260!5m2!1spt-PT!2sbr`;
                return endereco;
            }
        }
        return null;
    }
}
